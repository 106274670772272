@import '../../../../../scss/abstracts/variables';

svg.schedule-steps-icon {
  width: 32px;
  height: 32px;
}

.ssb-icon-active > path {
  fill: $blue-sapphire;
}

.ssb-icon-disabled > path {
  fill: $gray;
}

div.MuiStepConnector-vertical {
  margin-left: 15px;
  span.MuiStepConnector-lineVertical {
    border-left-width: 2px;
  }
}

.Mui-completed span {
  border-color: $blue-sapphire;
}

.scheduling-step {
  margin-top: 8px;

  @media (max-width: $lg) {
    margin-top: 0;
  }
  .MuiStepLabel-vertical {
    height: 5px;
  }

  .MuiStepLabel-label {
    @media (max-width: $lg) {
      display: flex;
    }
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0;
  }

  .MuiStepConnector-line {
    height: 2px;
    border: 0;
    background-color: $gray;
    border-radius: 1px;
  }

  .Mui-completed > span {
    background-color: $blue-sapphire;
  }

  .MuiStepConnector-root.MuiStepConnector-alternativeLabel {
    right: calc(50% + 11px);
    top: 14px;
  }
  .MuiTypography-subtitle1 {
    order: -1;
    margin-right: 4px;
  }
}

@media (min-width: $xl) {
  #stepper-1 {
    column-gap: 8%;
    padding: 0;
  }
  .scheduling-step .MuiStepConnector-alternativeLabel {
    left: calc(-50% - 50px);
  }
}

.scheduling-step {
  svg {
    background-color: white;
    z-index: 1;
  }
}
