.autocomplete {
  width: 100%;

  &.MuiAutocomplete-root {
    margin-top: 8px;
  }

  @media (max-width: $md) {
    width: 100%;
  }

  input {
    @include input-padding;

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
    }

    border-radius: 4px;
  }

  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      & fieldset {
        border: 1px solid $cultured;
      }
    }
  }

  .MuiFormControl-root .MuiInputBase-root {
    background-color: $white-smoke;
    padding-top: 0;
    padding-bottom: 0;

    input {
      @include input-padding;

      font-size: 16px;
      font-weight: 500;
      color: $black;
    }
  }
}
