@import '../../../../scss/abstracts/variables';

.verification-code {
  width: 100%;
  border-radius: 4px;

  input {
    font-size: 2rem;
    letter-spacing: 0.7rem;
  }
}
