@import '../../../../scss/main.scss';

.app-mockup-container {
  display: flex;
  flex-direction: row;

  h4.title,
  h6.title {
    color: $green;
    font-weight: bold;
  }

  h4.title {
    font-size: 24px;

    &.mobile {
      margin-top: 70px;
    }
  }

  h6.title {
    font-size: 20px;

    &.steps {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  h6.subtitle {
    font-size: 20px;
    line-height: 22px;
  }

  .app-mockup-image {
    max-height: 315px;
  }

  &.all-height {
    flex-direction: column;
    align-items: center;

    .app-mockup-image {
      max-height: 427px;
    }
  }

  .app-logos-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.all-height {
      align-items: flex-start;

      h6.title.steps {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .list-container {
        margin-bottom: 0;
      }
    }

    .horizontal-logos {
      margin-top: -5px;
      margin-bottom: 15px;

      display: flex;
      flex-direction: row;

      a img {
        margin-right: 5px;
      }
    }
  }
}

.list-container {
  margin-top: 0;
  padding-left: 15px;

  li::marker {
    font-size: 0.5em;
  }

  li {
    margin-bottom: 10px;
  }

  li h6 {
    font-size: 14px;
    font-weight: normal;
  }

  li a {
    color: $green;
  }
}

.download-image {
  max-width: 130px;
  &--small {
    max-width: 100px;
  }

  @media screen and (min-width: $sm) {
    max-width: 160px;
  }
}
