@import '../../../scss/abstracts/variables';

.login-layout {
  h3 {
    font-size: 2.25rem;
    color: $blue;
  }
  h6 {
    font-size: 1.2rem;
    color: $blue;
  }
  button.login-button {
    border-radius: 10rem;
  }
  form {
    .form-group {
      margin-bottom: 1rem;
    }
    .text-blue {
      color: $blue;
    }
  }
}
