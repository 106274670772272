@import '../../../../scss/abstracts/variables';

.providers {
  &-container {
    border-bottom: 1px solid $alice-blue;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  &-item {
    position: relative;
    bottom: -1px;
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      border-bottom: 3px solid $blue-sapphire;
    }
  }
}

.change-language {
  color: blue;
  cursor: pointer;
}

.booking-modal {
  position: relative;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-right: 2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border: 1px solid #ccc;
  background-color: #f2f2f2;

  &-close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    cursor: pointer;
  }
}
