@import '../../../../../scss/main.scss';

.appointment-container {
  @include center;

  display: flex;
  height: 320px;
  margin-bottom: 12px;

  .MuiCalendarPicker-viewTransitionContainer {
    overflow: hidden;
  }

  @media (min-width: $lg) {
    div[role='cell'] {
      width: 30px;
      height: 30px;
      margin: 2.5px;
      button {
        width: 29px;
        height: 29px;
      }
    }
  }

  @media (max-width: $md) {
    flex-direction: column;
  }

  .MuiCalendarPicker-root {
    overflow: hidden;
    height: 100%;

    .MuiMonthPicker-root {
      width: 100%;
      margin: 0;
    }
    @media (max-width: $sm) {
      width: fit-content;
    }
    .css-1dozdou {
      .css-sykobm {
        font-size: 16px;
        white-space: nowrap;
      }

      padding-left: 0;
    }
  }
}

.appointment-date-picker.MuiCalendarPicker-root {
  margin: 0;
  @media (min-width: $lg) {
    margin-right: 16px;
    .MuiCalendarPicker-viewTransitionContainer {
      div span {
        width: 36px;
      }
    }
  }
}

.appointment-available-hours {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
  align-items: flex-start;

  @media (max-width: $lg) {
    margin-left: 0 !important;
    width: 100%;
  }

  @media (min-width: $lg) {
    width: 50%;
  }
}

.radio-group.radio-buttons-available-times {
  overflow-y: auto;
  flex-wrap: nowrap;

  label {
    gap: 0;
  }
}

.circular-progress-availability {
  margin-top: 50px;
  align-self: center;
}

.appointment-date-time {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.MuiButtonBase-root.MuiPickersDay-root {
  font-weight: 600;
}

.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root {
  font-weight: 400;
}
