@mixin input-padding {
  padding-top: 12px;
  padding-bottom: 12px;
}

@mixin center {
  justify-content: center;
  align-items: center;
}

@mixin border($radius: 0, $width: 0, $style: '', $color: inherit) {
  @if $radius != 0 {
    border-radius: $radius;
  }

  @if $width != 0 {
    border: $width $style;
    border-color: $color;
  }
}

@mixin remove-margin($sides...) {
  @if length($sides) == 0 {
    margin: 0;
  } @else {
    @each $side in $sides {
      margin-#{$side}: 0;
    }
  }
}
