@import '../../../scss/abstracts/variables';

.auth-layout {
  form {
    .form-group {
      margin-bottom: 1rem;
    }
  }

  button {
    min-width: inherit;
  }
}
