@import '../../../../scss/abstracts/variables';

.typography-question {
  font-size: 22px;
  cursor: pointer;
}

.ul-answer {
  font-weight: 500;
  line-height: 28px;
}

.ul-disc {
  list-style-type: disc;
}

.ul-circle {
  list-style-type: circle;
}

.ul-square {
  list-style-type: square;
}
