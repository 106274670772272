.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.vh-full {
  height: 100vh;
}
.inline-block {
  display: inline-block;
}
