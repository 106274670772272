@import '../../../../scss/abstracts/variables';

.highlight-box {
  border-radius: 20px;
  background-color: $light-teal;
  padding: 20px 15px;

  &.spaced {
    margin-top: 30px;
  }

  &.spaced-horizontal {
    margin-top: 45px;
    margin-left: auto;
    margin-right: auto;

    padding-left: 30px;
    padding-right: 30px;

    max-width: calc($md - 60px);
  }
}
