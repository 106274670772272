@import '../../../../scss/main.scss';

.scheduled-appointment {
  @include center;

  display: flex;
  height: 5rem;
  margin: 1rem;

  .scheduled-appointment-label {
    margin: 0;
    padding-left: 1rem;
  }
}

.remove-line-separator {
  .title-basic-layout {
    border-bottom: none;
  }
}
