@import '../../../../scss/main.scss';

.agreements-payment {
  &.do-not-overlap {
    margin-top: 30px;
  }

  .MuiFormControlLabel-root {
    align-items: flex-start;
    padding: 8px 0;
  }

  .MuiTypography-root {
    font-size: 16px;
    font-weight: 400;

    a {
      font-weight: 600;
    }
  }
}

.agreement-details-loading {
  @include center;

  display: flex;
  min-height: 100px;
}

.agreements-dialog {
  button {
    margin: 10px 20px;
    min-width: min-content;
  }

  a {
    word-break: break-all;
  }
}

.agreement-dialog-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

#agreement-content {
  h1 {
    font-size: 1.17em;
  }

  h2 {
    font-size: 1em;
  }
}
