@import '../../../../scss/main.scss';

.container-box {
  display: grid;
  flex-direction: column;
  justify-content: center;
}

.logo-box {
  @include center;

  display: flex;
  margin-bottom: 35px;
}

.date-time-box {
  display: block;
}

.appointment-box {
  @include input-padding;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 12px;
  padding-right: 12px;
  gap: 8px;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(34, 47, 62, 0.07);
  border-radius: 8px;
  margin-bottom: 10px;
}

.circular-progress-congratulations {
  margin: 50px 0 50px;
}
