@import '../../../scss/abstracts/variables';

.typography-bmi {
  color: $gray-web !important;
}

.get-started-input {
  flex-grow: 1;
  border-radius: 4px;
}

.low-bmi-box {
  margin-top: 20px;

  a:link {
    text-decoration: none;
  }
}
