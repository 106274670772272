$theme-config: (
  'main-theme-color': $blue-sapphire,
  'progress-bar-theme': $salmon,
);

html {
  min-height: 100%;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  a {
    color: $blue-sapphire;
  }

  body {
    padding-bottom: 6rem;
  }
}
