@import '../../../scss/main.scss';

$content-width: 50;

.container-payment {
  .container-basic-layout {
    width: 60%;

    @media (max-width: $lg) {
      width: #{$content-width + 10}#{'%'};
    }

    @media (max-width: $md) {
      width: #{$content-width + 20}#{'%'};
    }

    @media (max-width: $sm) {
      width: #{$content-width + 40}#{'%'};
    }
  }
}

.inputs-payment {
  margin-bottom: 24px;

  .MuiTextField-root {
    min-width: -webkit-fill-available;
    min-width: -moz-available;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.details-discount-wrapper {
  @include border(10px);
  background-color: #fff;
  box-shadow: 0 0 12px 7px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  padding: 10px;
}

.details-discount-message {
  @include border(10px);
  background: #fff;
  color: $blue-sapphire;
  font: 700 14px/21px $main-font;
  line-height: 16px;
  text-align: center;
  padding: 3px;

  &:after {
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    content: '';
    height: 0;
    position: relative;
    top: 37px;
    left: 0;
    width: 0;
  }
}

.details-payment {
  @include border(4px);
  background-color: $light-white;
  box-shadow: 0px 2px 4px rgba(148, 148, 148, 0.25);
  padding: 16px 24px;
  margin-bottom: 24px;

  .coverage-discount,
  h3 {
    font-size: 20px;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    font-family: $main-font;
  }

  @media (max-width: $lg) {
    margin-bottom: 40px;
  }
}

.details-title-payment {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $black;
  font-family: $main-font;
}

.plan-payment {
  span {
    font-size: 16px;
    line-height: 20px;
  }

  .MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
  }

  .MuiRadio-root {
    padding: 6px;
    padding-right: 0;
    margin-right: -3px;
  }

  .MuiTypography-root {
    padding-top: 1px;
  }

  .radio-group {
    label {
      width: 46%;
      min-width: 102px;
      background-color: white;
    }
  }
}

.coverage-bubble {
  @include border(20px);

  background: $blue-sapphire;
  color: $white;
  font: 600 13px/28px $main-font;
  height: 28px;
  text-align: center;
  width: 90px;
  margin-left: 45%;
  margin-bottom: 10px;
  padding-left: 5px;

  &:after {
    border-color: $blue-sapphire transparent transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    content: '';
    height: 0;
    width: 0;
    position: relative;
    top: 24px;
    left: -55px;
  }
}

.coverage-price {
  color: $black;
}

.coverage-discount {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
  color: $gray-web;
  margin-bottom: 0;
}

.coverage-discount-new-price {
  color: rgb(243, 121, 98);
  margin-top: 0;
}

.coverage-open {
  font-family: $main-font;
  color: $blue-sapphire;
  margin-top: 20px;
  margin-left: -5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.MuiSvgIcon-root.arrow-collapse {
  transform: rotate(-180deg);
  transition: 0.5s ease-out;
}

.MuiSvgIcon-root.arrow-expand {
  transform: rotate(0deg);
  transition: 0.5s ease-out;
}

.coverage-container-payment {
  font-family: $main-font;
  color: $gray-web;

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
  }

  span {
    font-size: 14px;
    line-height: 18px;
  }
  li {
    display: flex;
    padding: 4px 0;
  }

  svg {
    padding-top: 4px;
    padding-right: 8px;
  }
}

.coverage-list-payment {
  margin-bottom: 8px;

  span {
    font-weight: 400;
  }
}

.coverage-legend-payment {
  margin-top: 8px;

  span {
    font-weight: 600;
  }

  .coverage-details {
    font-size: 12px;
    line-height: 16px;
  }
}
