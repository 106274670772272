@import '../../../scss/main.scss';

.manual-insurance-label {
  text-align: left;
  color: $black;
}

.border {
  @include border(4px, 2px, solid);
}

.border:hover {
  border-width: 2px;
}

.manual-insurance-input-field {
  margin-top: 8px !important;

  @media (max-width: $md) {
    width: 100%;
  }

  input {
    @include input-padding;
    @include border(4px);

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      & fieldset {
        @include border(0, 1px, solid, $cultured);
      }
    }
  }

  .MuiFormControl-root .MuiInputBase-root {
    background-color: $white-smoke;
    padding-top: 0;
    padding-bottom: 0;

    input {
      @include input-padding;

      font-size: 16px;
      font-weight: 500;
      color: $black;
    }
  }
}
