@import '../../../../scss/main.scss';

.verification-code {
  @include input-padding;
}

.verification-code-again {
  font-family: $main-font;
  font-size: 14px;
  font-weight: 600;

  a {
    text-decoration: underline;
  }
}
