@import '../../../../scss/main.scss';

.upload-box,
.preview-box {
  @include center;
  @include border(4px);

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 327px;
  height: 184px;
  background: $light-white;
  flex: none;

  path:hover {
    cursor: pointer;
    fill: #23778b;
  }
}

.upload-box {
  @include border(0, 2px, dashed, #dee5ec);
  padding: 1rem;
}

.preview-box {
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.camera-content {
  video {
    width: 100%;
  }
}

.camera-bottom-bar {
  &.MuiDialogActions-spacing {
    justify-content: center;
  }
}
