@import '../../../../../scss/main.scss';

.radio-group {
  label {
    @include remove-margin;
    border-radius: 4px;
    gap: 8px;
    min-height: 48px;
  }

  span:hover {
    background-color: unset;
  }
}

.radio-button {
  @include input-padding;

  padding-left: 12px;
  padding-right: 3px;

  &:hover {
    background-color: transparent;
  }
}
