@import '../../../../scss/main.scss';

.toast-body {
  align-items: center;
  display: flex;
}

.Toastify__toast-body {
  font-size: 17px;
  font-family: $main-font;
  font-weight: 600;
}

.Toastify__toast-icon {
  padding-right: 10px;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    left: 13%;
  }
}

:root {
  --toastify-color-error: #{$salmon};
  --toastify-color-info: #{$blue-sapphire};
  --toastify-color-success: #{$bud-green};
}
