@import '../../../../scss/main.scss';

.container-progress-bar {
  padding-top: 20px;
}

.logo-progress-bar {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;

  img {
    width: 82px;
  }
}

.partner-logo-progress-bar {
  position: absolute;

  top: 20px;
  left: 10px;

  img {
    width: 95px;
  }
}

.partner-logo-ipo-progress-bar {
  top: 10px;
}

.login-progress-bar {
  position: absolute;
  top: 20px;
  right: 20px;

  @media (max-width: $md) {
    position: inherit;
    margin-top: 15px;
  }

  a {
    font-weight: 700;
  }
}

.logout-progress-bar {
  top: 10px;
  right: 20px;

  &.MuiButtonBase-root {
    position: absolute;
  }
}

.progress-bar {
  background-color: $alice-blue !important; // So MUI stops overriding this
  width: 100vw;
  height: 4px;
}

.bar-progress-bar {
  height: 4px;
  background-color: $salmon;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.indicator-container {
  height: calc(100vh - 115px);
  display: flex;
  justify-content: center;
  align-items: center;
}
