@import '../../../../scss/abstracts/variables';

.input {
  flex-grow: 1;
  border-radius: 4px;
}

.title-space {
  margin-top: 20px;
}

.max-content {
  flex-grow: 1;
}

.autocomplete-custom {
  &.MuiAutocomplete-root {
    margin-top: 0px !important;
  }

  .MuiFormControl-root .MuiInputBase-root {
    input {
      padding-top: 17px !important;
      padding-bottom: 17px !important;
    }
  }
}

.input-custom {
  width: 100%;
}

.insurance-box {
  h4 {
    text-align: center;
  }

  .upload-box,
  .preview-box {
    width: auto;
  }

  button {
    min-width: 100%;
    height: 2rem;
    margin-top: 0.8vw;
  }
}

.modal {
  h3.appointment-type-selection-title {
    font-weight: normal;
  }
}
