@import '../../../scss/main.scss';

.radio-checked,
.radio-unchecked {
  @include border(4px, 2px, solid, $alice-blue);
  padding: 10px 14px;
  width: 25%;
  margin: 8px 0 4px 0;

  span {
    padding: 0;
    color: $alice-blue;
  }

  @media (max-width: $md) {
    width: 35%;
  }
}

.radio-checked {
  border-color: $blue-sapphire;
}

.radio-box {
  display: flex;
  justify-content: center;
}

.zip-code-text-field {
  @media (max-width: $md) {
    width: 100%;
  }
}
