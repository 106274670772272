@import '../../../../scss/main.scss';

.auth-layout {
  background-color: $white;

  .right-side {
    background-color: $light-cyan;
  }
}

.login-sidebar {
  padding: 5rem;

  img {
    width: $logo-width;
  }

  @media (max-width: $lg) {
    padding: 5rem 0.25rem;
  }
}
