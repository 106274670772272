.modal-overflow {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  justify-content: center;
  align-items: center;

  .modal {
    background-color: white;
    border-radius: 0.5rem;
    padding: 2.5rem 1.5rem;
    width: calc(100% - 3rem);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    max-width: 400px;

    &-button {
      min-width: auto;
      width: 100%;
    }
  }
}
