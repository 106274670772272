@import '../../../scss/main.scss';

.coverage {
  h4,
  h5 {
    text-align: center;
  }

  p {
    margin-bottom: 0;
  }

  h6 {
    color: $gray-web;
  }
}

.coverage-no-price {
  text-decoration: line-through;
}

.coverage-bottom-box {
  border-top: solid 1.5px $alice-blue;
  padding-top: 10px;
}

.coverage-list {
  width: 50%;

  ul {
    padding-top: 0;
    padding-bottom: 0;
  }

  li {
    padding: 1px 0;
  }
}

.coverage-list-full {
  width: 100%;
}

div {
  .coverage-list-divider {
    color: $alice-blue;
    border: solid 1px;
    align-self: center;
  }

  .vertical {
    height: 18em;
    margin: 0 20px 0 10px;
  }

  .horizontal {
    width: 22em;
    margin: 10px 0 10px 0;
  }
}
