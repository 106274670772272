// Light
$philippine-silver: #b6b6b6;
$gainsboro: #dbdada;
$white-smoke: #f9f9f9;
$iceberg: #dcf0ef;
$pale-aqua: #abd9d6;
$powder-blue: #b9e1de;
$light-cyan: #cbe8e6;
$white: #fff;
$alice-blue: #dee5ec;
$light-slate-gray: #f8f9f9;
$light-white: #fafafa;
$cultured: #f4f7f4;
$light-teal: #ecf6f6;

// Dark
$roman-silver: #85868d;
$bright-gray: #323648;
$ship-grey: #32354a;
$gravel: #4b4846;
$black: #424242;
$gray-web: #7d7c7a;
$gray: #bdbdbd;
$green: #1a667a;

// Others
$salmon: #f37962;
$blue: #326478;
$blue-sapphire: #278ba5;
$bud-green: #73af55;

// Fonts
$main-font: 'Proxima Nova';

// Widths
$logo-width: 82px;

// Breakpoints
$xs: 320px;
$sm: 480px;
$md: 768px;
$lg: 960px;
$xl: 1024px;
